import React from 'react';
import {Typography, Link, Box} from '@mui/material';
import {Star} from '@mui/icons-material';

const darkGrey = '#333333'; // Dunkelgrau Farbcode

const MyLegend = () => {
  return (
      <><Box sx={{marginTop: '10px'}}>
        <Typography component="span" variant="body2" sx={{color: darkGrey, fontSize: '0.7REM'}}>
          <Star sx={{fontSize: '0.5rem', color: darkGrey, verticalAlign: '0.3REM', marginTop: '0.1rem'}}/> Amazon ist
          unser Werbepartner. Wir verwenden teilweise Affiliate Links.
        </Typography>
      </Box>
        <Box sx={{ marginBottom: '20px', color: darkGrey, fontSize: '0.5rem', lineHeight: 0.3}}>
          <Typography component="span" variant="body1" sx={{color: darkGrey, fontSize: '0.5REM'}}>
            Legende:<br/>
            Leistung: Wie leistungsfähig ist das Produkt in der Kernfunktionalität (Saugkraft, etc.)<br/>
            Features: Wie viele Zusatzmöglichkeiten / Zubehör gibt es.<br/>
            Robustheit: Sind Ausfälle bereits nach kurzer Zeit bekannt. Wie empfindlich ist das Produkt.<br/>
            Nachhaltigkeit: Wird das Lieferkettengesetz eingehalten. Ist die Verfügbarkeit von Ersatzteilen geregelt.<br/>
            Vertrauenswürdigkeit: Wird in einem demokratischen System hergestellt, ist der Hersteller verpflichtet europäische
            Datenschutzrichtlinien einzuhalten.
          </Typography>
        </Box>
      </>
)
}

export default MyLegend;
