import React from 'react';
import { Paper, Container } from '@mui/material';
import Headline from "../components/Headline";
import {Link, Navigate} from "react-router-dom";


//Texts
import jsonText from '../texts/topics.json';
import TextToHTML from "../components/TextToHTML";
import Recommendation from "../components/Recommendation";

const Intro_page = ({topic}) => {

  const content = jsonText[topic] ? jsonText[topic] : jsonText['portal'];

  return (
      <Container maxWidth="md" sx={{marginTop: '20px'}}>
        <Paper elevation={4} sx={{ padding: '20px', marginBottom: '20px', marginTop: '3rem', height: 'content', position: 'relative' }}>
          <Headline text={content.intro_headline}/>
          <div style={{ textAlign: 'center' }}>
            <img
                src={content.img}
                alt="Unsplash Bild"
                style={{ width: '60%', borderRadius: '20px', }}
            />
          </div>
        </Paper>
        <Paper elevation={8} sx={{ padding: '20px', marginBottom: '0.5rem', marginTop: '1rem', height: 'content', position: 'relative' }}>
          <TextToHTML>{content.intro1}</TextToHTML>
        </Paper>
        <Recommendation recommend={content.recommend}></Recommendation>
        <Paper elevation={8} sx={{ padding: '20px', marginBottom: '20px', marginTop: '0rem', height: 'content', position: 'relative' }}>
          <TextToHTML>{content.text}</TextToHTML>
        </Paper>
      </Container>

  );
};

export default Intro_page;
