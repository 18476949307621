import React from 'react';
import { Box, Typography } from '@mui/material';
import AmazonProductLink from "./Amz_link"; // assuming you're using Material-UI

const Price = ({ preis_upv, preis, amz_lnk }) => {
  return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <AmazonProductLink amz_lnk={amz_lnk}/>
        <Box sx={{ textAlign: 'right' }}>
          <Typography variant="body2" color="textSecondary" component="span" sx={{ marginRight: '1rem', textDecoration: 'line-through' }}>
            {preis_upv}
          </Typography>
          <Typography variant="h5" component="span">
            {preis}
          </Typography>
        </Box>
      </Box>
  );
};

export default Price;
