import React from 'react';
import {Typography, Link} from '@mui/material';
import {Star} from '@mui/icons-material';
import Button from "@mui/material/Button";

const darkGrey = '#000000';

const AmazonProductLink = ({amz_lnk}) => {
  return (
      <Typography component="span" variant="body1">
          <Button
              component="a"
              href={amz_lnk}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                  backgroundColor: 'green', // Setzt den Hintergrund auf grün
                  color: 'white', // Setzt die Textfarbe auf weiß
                  textDecoration: 'none', fontSize: '0.6rem',
                  '&:hover': {
                      backgroundColor: 'darkgreen' // Setzt die Hintergrundfarbe beim Hover-Effekt auf dunkelgrün
                  }
              }}
          >
              Best Price
          </Button>
        <Star style={{color: 'grey', fontSize: '0.5rem', verticalAlign: 'text-top'}}/>
      </Typography>
  );
}

export default AmazonProductLink;
