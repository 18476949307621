import { Box } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarBorderIcon from '@mui/icons-material/StarBorder';

export default function ProductStars({ stars }) {
  console.log(stars)
  const filledStars = Math.floor(stars);
  const hasHalfStar = stars % 1 !== 0;
  const emptyStars = Math.max(0, 5 - filledStars - (hasHalfStar ? 1 : 0));

  return (
      <Box display="flex" alignItems="center" sx={{mt:"0.5REM", mb:"0.5rem"}}>
        {[...Array(filledStars)].map((_, index) => (
            <StarIcon key={index} sx={{ color: '#FFD700', marginRight: '2px' }} />
        ))}
        {hasHalfStar && <StarHalfIcon sx={{ color: '#FFD700', marginRight: '2px' }} />}

        {[...Array(emptyStars)].map((_, index) => (
            <StarBorderIcon key={index} sx={{ color: '#888', marginRight: '2px' }} />
        ))}

      </Box>
  );
}
