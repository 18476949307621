import { Typography } from '@mui/material';

export default function ProductTextHeadline({ headln }) {

  return (
      <Typography variant="h5" fontWeight="bold">
        {headln}
      </Typography>
  );
}
