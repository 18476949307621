import React from 'react';
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import Box from "@mui/material/Box";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: null,
    title: {
      display: true,
      text: 'Bewertung',
    },
  },
  scales: {
    x: {
      min: -10,
      max: 10,
    },
  },
};

const labels = ['Leistung', 'Features', 'Robustheit', 'Nachhaltigkeit', 'Vertrauenswürdigkeit'];


export default function Chart({product_data}) {
  const dataValues = labels.map( (i, index) => {return product_data[index] } );
  const data = {
    labels,
    datasets: [
      {
        label: null,
        data: dataValues,
        backgroundColor: dataValues.map(value => value <= 0 ? 'rgba(255, 0, 0, 0.5)' : 'rgba(0, 255, 0, 0.5)'),
        borderColor: dataValues.map(value => value <= 0 ? 'rgb(255, 0, 0)' : 'rgb(0, 255, 0)'),
        borderWidth: 1,
      },
    ],
  };
  return (
  <Box sx={{width: "100%", margin: "auto", display: "flex", justifyContent: "center", height: "10rem"}}>
    <Bar options={options} data={data}/>
  </Box>
)
  ;
}
