import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";

// import text
import jsonText from '../texts/topics.json';
const  suburl  = jsonText.suburl;

const drawerWidth = 240;


function DrawerAppBar(props) {

  const navItems = [props.subdomain, 'Vergleich', 'About', 'Contact'];
  // const navTarget = [props.subdomain, 'Product', 'About', 'Contact'];

  const {window} = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();


  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleClick = (i) => {
    navigate('/'+ navItems[i] )
  }

  // This is the drawer, shown in mobile mode (sx=block)
  const drawer = (
      <Box onClick={handleDrawerToggle} sx={{textAlign: 'center'}}>
        <Typography variant="h6" sx={{my: 1}}>
          Menü
        </Typography>
        <Divider/>
        <List>
          {navItems.map((item, index) => (
              <ListItem key={item} disablePadding>
                <ListItemButton sx={{textAlign: 'center'}} onClick={ ()=>handleClick(index) }>
                  <ListItemText primary={item}/>
                </ListItemButton>
              </ListItem>
          ))}
        </List>
      </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
      <Box sx={{display: 'flex'}}>
        <CssBaseline/>
        <AppBar component="nav">
          <Toolbar>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{mr: 2, display: {sm: 'none'}}}
            >
              <MenuIcon/>
            </IconButton>
            <Typography
                variant="h6"
                component="div"
                sx={{flexGrow: 1, display: {xs: 'none', sm: 'block'}}}
            >
              Empfehlung
            </Typography>
            <Box sx={{display: {xs: 'none', sm: 'block'}}}>
              {navItems.map((item, index) => (
                  <Button key={item} sx={{color: '#fff'}} onClick={ ()=>handleClick(index) }>
                    {item}
                  </Button>
              ))}
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer component="nav"
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: {xs: 'block', sm: 'none'},
              '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
            }}>
          {drawer}
        </Drawer>
        <nav></nav>
        <Box component="main" sx={{p: 0}}>
          <Toolbar/>
        </Box>
      </Box>
  );
}


export default DrawerAppBar;
