import { Box, Typography } from '@mui/material';

export default function Headline({text}) {
  return (
      <Box
          marginBottom={3}
          sx={{
            textAlign: 'center',
            borderRadius: '10px',
            border: '0.1rem dashed black',
            padding: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
      >
        <Typography variant="h5" fontWeight="bold">
          {text}
        </Typography>
      </Box>
  );
}
