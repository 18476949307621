import React from 'react';
import {Box, Typography, Paper} from '@mui/material';
import Summary from "./Summary"
import Price from "./Price"
import ProductStars from "./ProductStars";
import ProductTextHeadline from "./ProductTextHeadline";
import Chart from "./Chart";
import TextToHTML from "./TextToHTML";
import AmazonProductLink from "./Amz_link";

const Products = ({img, headln, txt, china, stars, product_data, preis_upv, preis, amz_lnk}) => {
    return (
        <Paper elevation={8}
               sx={{padding: '20px', marginBottom: '20px', height: 'content'}}> {/* Erhöhe den Abstand zum Rand */}
            <Box>
                <Box style={{
                    float: 'left',
                    marginRight: '20px',
                    width: '50%',
                    maxWidth: '15rem',
                    height: 'auto',
                    overflow: 'hidden',
                    borderRadius: '10%'
                }}>
                    <img src={'../' + img} alt="Bild" style={{width: '100%', maxHeight: '100%', objectFit: 'cover'}}/>
                </Box>
                <ProductTextHeadline headln={headln}/>
                <ProductStars stars={stars}></ProductStars>
                <Typography sx={{fontSize: '1rem', textAlign: 'justify'}}> {/* Füge textAlign hinzu */}
                    <TextToHTML>{txt}{china}</TextToHTML>
                </Typography>

                <Chart product_data={product_data}></Chart>
            <br/>
            <Price preis_upv={preis_upv} preis={preis} amz_lnk={amz_lnk}></Price>
        </Box>
</Paper>
)
    ;
};

export default Products;
