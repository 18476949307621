import React , { useRef } from 'react';
import {AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, Container, Box, styled} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Products from './components/Products';
import Headline from './components/Headline';
import DrawerAppBar from "./components/DrawerAppBar";
import Template from "./Template";
import Price from "./components/Price";
import Product_page from "./pages/Product_page";
import About_page from "./pages/About_page";
import Contact_page from "./pages/Contact_page";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Intro_page from "./pages/Intro_Page";
import {SignalCellularNull} from "@mui/icons-material";

const App = () => {

  const currentUrl = new URL(window.location.href).toString();
  const subdomain =  extractSubdomain(currentUrl);


  function extractSubdomain(url) {
    // Entferne das Protokollteil (http:// oder https://) von der URL
    if (url.startsWith("http://")) {
      url = url.slice(7); // Entferne die ersten 7 Zeichen (die Länge von "http://")
    } else if (url.startsWith("https://")) {
      url = url.slice(8); // Entferne die ersten 8 Zeichen (die Länge von "https://")
    }

    // Zerlege die URL in ihre Teile
    const parts = url.split('/');

    // Extrahiere den ersten Teil hinter dem Domainnamen
    let subdomain = parts[1].toLowerCase(); // Beachte, dass der Index von 1 abhängig von der URL-Struktur sein kann

    // Wenn der Subdomain-Teil in der Liste enthalten ist, setze ihn auf 'Portal'
    const found = ['', 'intro', 'vergleich', 'about', 'contact'].includes(subdomain);
    if (found) subdomain = 'portal';

    return subdomain;
  }

  return (
      <>

        <BrowserRouter>
          <DrawerAppBar subdomain={subdomain}></DrawerAppBar>
          <Routes>
            <Route path={'/'} element={<Intro_page/>}></Route>
            <Route path={'/'+subdomain} element={<Intro_page topic={subdomain}/>}></Route>

            <Route path={'saugroboter/vergleich/'} element={<Product_page topic={'saugroboter'}/>}></Route>
            <Route path={'wischroboter/vergleich/'} element={<Product_page topic={'wischroboter'}/>}></Route>

            <Route path='/contact' element={<Contact_page/>}></Route>
            <Route path='/vergleich/' element={<Product_page topic={subdomain}/>}></Route>

            <Route path='/about' element={<About_page/>}></Route>
            <Route path='/intro' element={<Intro_page/>}></Route>
          </Routes>
        </BrowserRouter>

      </>
  );
};

export default App;
