import React, {lazy} from 'react';
import {AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, Container, Box, styled} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Products from '../components/Products';
import Headline from '../components/Headline';
import Summary from '../components/Summary'

// https://convert.leiapix.com/upload
// LICEcup for MAC
import jsonText from "../texts/topics.json";
import Recommendation from "../components/Recommendation";
import MyLegend from "../components/MyLegend";

const Product_page = ({topic}) => {

      const content = jsonText[topic];

      const genProductList = (content) => {
        const parray = [];
        content.products.map((obj, i) => parray.push(<Products img={content.products[i].img}
                                                                   headln={content.products[i].headline}
                                                                   txt={content.products[i].text}
                                                                   china={content.products[i].china}
                                                                   stars={content.products[i].stars}
                                                                   product_data={content.products[i].data}
                                                                   preis_upv={content.products[i].preis_upv}
                                                                   preis={content.products[i].preis}
                                                                   amz_lnk={content.products[i].amz_lnk}/>)
        )
        return parray;
      }

      return (
          <>
            <Container maxWidth="md" sx={{marginTop: '20px'}}>
              <Headline text={content.product_page_headline}/>
              {genProductList(content)}
              <Summary summary={content.summary}></Summary>
              <Recommendation recommend={content.recommend}></Recommendation>
              <MyLegend></MyLegend>
            </Container>
          </>
      );
    }
;

export default Product_page;
