import React from 'react';
import { Paper, Typography, Container } from '@mui/material';

const About_page = () => {
  return (
      <Container maxWidth="md" sx={{marginTop: '20px'}}>
        <Paper elevation={8} sx={{ padding: '20px', marginBottom: '20px', marginTop: '3rem', height: 'content', position: 'relative' }}>
          <Typography variant="h6" fontWeight="bold" gutterBottom>
            Das sind wir:
          </Typography>
          <div style={{ textAlign: 'center' }}>
            <img
                src="https://media.istockphoto.com/id/1315630429/de/foto/nahaufnahme-von-jungen-und-frische-energie-gesch%C3%A4ftsleute-setzen-ihre-starken-h%C3%A4nde-zusammen.webp?b=1&s=170667a&w=0&k=20&c=yDchqPxySfWYqJsGDTpYaV98c1dD5wCPu1BMJ5mUTJs="
                alt="Unsplash Bild"
                style={{ width: '80%', borderRadius: '20px', }}
            />
          </div>
        </Paper>
        <Paper elevation={0}>
          Ein gutes innovatives Team zeichnet sich durch seine Fähigkeit aus, kreative Ideen zu generieren und effektiv umzusetzen. Jedes Teammitglied bringt seine einzigartigen Stärken und Perspektiven ein, was zu einem vielfältigen Ideenpool führt. Durch offene Kommunikation und eine Kultur des Respekts werden Hindernisse überwunden und innovative Lösungen entwickelt. Teammitglieder unterstützen sich gegenseitig, teilen ihr Wissen und arbeiten gemeinsam an Herausforderungen. Flexibilität und Anpassungsfähigkeit sind Schlüsselqualitäten, die es dem Team ermöglichen, sich an Veränderungen anzupassen und neue Möglichkeiten zu erkunden. Mit einem klaren Ziel vor Augen und einem gemeinsamen Engagement für Exzellenz schafft das Team nachhaltige Innovationen, die einen positiven Einfluss haben.
        </Paper>
      </Container>

  );
};

export default About_page;
