/*export default function TextToHTML({children}) {
  return (
      <div dangerouslySetInnerHTML={{__html: children}}/>
  )
};
*/

import Button from "@mui/material/Button";
import React, { useState } from "react";

export default function TextToHTML({ children }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const MAX_LENGTH = 220; // Die maximale Anzahl der Zeichen, bevor "more" angezeigt wird.

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  // Kombiniere alle Kinder in einen einzigen String
  const combinedText = React.Children.toArray(children).join('');

  const shouldShowMoreButton = combinedText.length > MAX_LENGTH;
  const displayedText = isExpanded ? combinedText : combinedText.substring(0, MAX_LENGTH) + (shouldShowMoreButton ? '...' : '');

  return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: displayedText }} style={{ display: 'inline', fontSize: '1.0rem'}} />
        {shouldShowMoreButton && (
            <Button
                onClick={handleToggle}
                size="small" // Macht den Button kleiner
                sx={{
                  ml: 1, // Marginal links für etwas Abstand vom Text
                  fontSize: '0.6rem', // Noch kleinere Schriftgröße
                  padding: '2px 6px', // Weniger Padding
                  minWidth: 'auto', // Minimale Breite entfernen
                  backgroundColor: '#D3D3D3', // Hellgraue Hintergrundfarbe
                  color: 'black', // Textfarbe
                  textDecoration: 'none',
                  '&:hover': {
                    backgroundColor: '#A9A9A9', // Dunkleres Grau beim Hover
                  },
                }}
            >
              {isExpanded ? 'less' : 'more'}
            </Button>
        )}
      </div>
  );
}


