import React from 'react';
import Typography from '@mui/material/Typography';
import {Box} from "@mui/system";
import {Paper} from "@mui/material";
import TextToHTML from "./TextToHTML";

export default function Recommendation ({recommend}) {
  return (
      <Paper elevation={8}  sx={{ padding: '10px', marginBottom: '10px',  height: 'content'}}>
        <Box sx={{ marginRight: 2, marginLeft: 2 }}>
          <Typography variant="body1" sx={{ textAlign: 'justify' }}>
            <TextToHTML>{recommend}</TextToHTML>
          </Typography>
        </Box>
      </Paper>
  );
};
