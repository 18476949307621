import React from 'react';
import {Paper, Typography} from '@mui/material';
import {Container} from "@mui/system";

const Contact_page = () => {
  return (
      <Container maxWidth="md" sx={{marginTop: '20px'}}>
        <Paper elevation={8} sx={{padding: '20px', marginBottom: '20px', marginTop: '3rem', height: 'content'}}>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            Cookies:
          </Typography>
          <Typography variant="body1">
            Wir verwenden keine Cookies zum Tracken der user. ;-)
            <br/>
            <br/>
          </Typography>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            Impressum:
          </Typography>
          <Typography variant="body1">
            b&w reprostudio<br/>
            Am Fuchsbau 4
            <br/>
            76359 Marxzell-Burbach
            <br/>
            <br/>
            bundw_repro@web.de
          </Typography>
        </Paper>
        </Container>
        );
        };

        export default Contact_page;
